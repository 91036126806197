//Page Setting

//----------------------------------------------

export const PAGE_SETTING = {
  name: 'Nestflo'
};

export const BOOK_DEMO_URL =
  // 'https://calendly.com/roland-tao-55/30min?month=2024-02';
  'https://nestflo.zohobookings.com/#/4685022000000042016';
export const VIDEO_URL =
  'https://kunpro.s3.eu-west-2.amazonaws.com/sites/h264_1080_best_2025.mp4';

//goodmanlilley
export const GOODMANLILLEY_URL =
  'https://kunpro-prod.s3.eu-west-2.amazonaws.com/site/Lark20240918-042252.mp4';

//GREENWOODS_URL
export const GREENWOODS_URL =
  'https://kunpro.s3.eu-west-2.amazonaws.com/sites/Lark20240903-105025.mp4';

//BESTE_JEFFER_URL˚
export const BESTE_JEFFER_URL =
  'https://kunpro-prod.s3.eu-west-2.amazonaws.com/site/Lark20240918-042056.mp4';
export const BESTE_JEFFER_lTD_URL = '';
export const SYZO_LTD_URL =
  'https://kunpro-prod.s3.eu-west-2.amazonaws.com/site/Lark20240918-042301.mp4';

export const PETER_ELLIS_URL =
  'https://kunpro-prod.s3-eu-west-2.amazonaws.com/site/Lark20241021-130240.mp4';

export const RG_PROPERTY_URL =
  'https://kunpro-prod.s3.eu-west-2.amazonaws.com/site/Lark20240918-035839.mp4';

export const REGISTER_URL = 'https://office.nestflo.ai/auth/register/';
export const LOGIN_URL = 'https://office.nestflo.ai/auth/login/';

export const MENU = [
  {
    title: 'Why Use Nestflo?',
    href: '/#link_1'
  },
  {
    title: 'CASE STUDIES',
    href: '/#link_logos'
  },
  {
    title: 'FEATURES',
    href: '/#link_2'
  },
  {
    title: 'NESTFLO GETS YOU STARTED',
    href: '/#link_4'
  },
  {
    title: 'PRICING',
    href: '/#link_5'
  }
  // {
  //   title: 'QUICK START GUIDE',
  //   href: '/#js-start'
  // },
  // {
  //   title: 'LOGIN',
  //   href: LOGIN_URL
  // }
];
